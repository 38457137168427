<template>
    <div class="account-layout">
        <div class="account-container">
            <div class="account-layout-left">
                <div class="left-title">Account Information</div>
                <div style="marginTop:7px">
                    <ul>
                        <li :class='{"active-li" : active === "/accountSetting/accountInfo"}'><router-link to="/accountSetting/accountInfo">Basic Information</router-link></li>
                        <li :class='{"active-li" : active === "/accountSetting/changePassword"}'><router-link to="/accountSetting/changePassword">Password</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="account-layout-right">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {
            }
        },
        computed:{
            active(){
                return this.$route.fullPath
            }
        }
    }
</script>

<style lang="less" scoped>
.account-layout{
    width: 100%;
    min-width: 1248px;
    min-height: calc(100vh - 56px);
    background-color: #fff;
}
.account-container{
    width: 1040px;
    margin:48px auto 0;
    display: flex;
    .account-layout-left{
        // width: 112px;
        .left-title{
            height: 28px;
            background: #fff;
            font-size: 15px;
            font-weight: 700;
            color: rgba(0,0,0,.8);
            line-height: 28px;
            padding-left: 16px;
        }
        ul{
            li{
                list-style: none;
                color: rgba(0, 0, 0, 0.8);
                cursor: pointer;
                height: 40px;
                display: flex;
                padding: 0 16px;
                overflow: hidden;
                position: relative;
                align-items: center;
                line-height: 40px;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                a{
                    color: #000;
                    display: block;
                    width: 100%;
                }
            }
            .active-li{
                position: relative;
                background: #f0f7ff;
                a{
                    color: @primary-color;
                }
            }
        }
    }
    .account-layout-right{
        width: 845px;
        min-height: 312px;
        border: 1px solid #ebebeb;
        margin-left: 16px;
    }
    .no-border{
        border-color: #fff;
    }
}
</style>